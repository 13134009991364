import React, { useState, useEffect } from 'react';

import 'assets/styles/pages/plantationregister.scss';

import Plantationbreadcumb from 'components/register-plantation/plantationbread';

import { planterdetailsData } from 'assets/data/planter-detail-list';

import { truncate } from 'lodash';

import Form from 'react-bootstrap/Form';

import { Link, navigate } from 'gatsby';

import { useForm } from "react-hook-form";

import axios from "axios";


import speciestreeicon from 'assets/images/icons/species-tree-icon.svg';

import closesmall from 'assets/images/icons/close-small-icon.svg';


import binicon from 'assets/images/icons/icon-metro-bin.svg';

import penciledit from 'assets/images/icons/icon-edit-pencil.svg';

import { ToastContainer, toast } from "react-toastify";
import { optionData } from 'assets/data/options';
import Viewthubnailslider from 'components/register-plantation/viewthumbnail-slider';
import Layout from 'layout';
import SEO from 'components/seo';

import { Modal, Spinner } from "react-bootstrap"
import PlantationListDetails from 'services/plantation/plantation.service';
import moment from 'moment';
const Plantationregister = ({ location }) => {
  const dataValue = (location.state && location.state.registerData !== undefined) ? location.state.registerData : null;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const ds = new PlantationListDetails();

  const [plantValue, setPlantValue] = useState("Multiple");
  const [addCount, setAddCount] = useState("");
  const [speiceOption, setSpeiceOption] = useState("Mango");
  const [addError, setAddError] = useState(false);
  const [addSamplingList, setAddSamplingList] = useState([]);
  const [newSamplingList, setNewSamplingList] = useState([]);
  const [speciesList, setSpeciesList] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [listShow, setListShow] = useState([]);
  const [fileError, setFileError] = useState([false]);
  const [countError, setCountError] = useState(false);
  const [allowFiles, setAllowFiles] = useState(false);
  const [loading, setLoading] = useState(false);
  const [positionValue, setPositionValue] = useState("");
  const [formRegisterData, setFormRegisterData] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");


  // const [plantationList, setPlantationList] = useState([]);
  // const loadDataOnlyOnce = () => {
  //   const url = "https://run.mocky.io/v3/48158748-61a4-452f-9621-d5d7cb2c785d";
  //   axios
  //     .get(url)
  //     .then((response) => response.data)
  //     .then((data) => {
  //       setPlantationList(data);
  //     });
  // };

  // useEffect(() => {
  //   loadDataOnlyOnce();
  // }, []);
  //console.log("plantationList", plantationList)

  const plantTypeChange = (e) => {
    let targetValue = e.target.value
    setPlantValue(targetValue);

    if (targetValue === "Single") {
      setAddSamplingList([]);
      setAddCount("");
      setAddError(false)
      setCountError(false)
    }
  }

  const speciesChange = async (e) => {
    await setSpeiceOption(e.target.value)
    setAddCount("")
  }
  const countChange = (e) => {
    e.preventDefault()
    let countVal = e.target.value
    setAddCount(countVal)
  }
  const addSaplings = async (event) => {
    event.preventDefault();

    if (addCount !== "") {
      const data = addSamplingList
      let newData = { "count": addCount, "option": speiceOption };
      const dataIndex = data.findIndex((item) => item.option === newData.option);
      if (dataIndex >= 0) {
        data[dataIndex].count = (
          Number(data[dataIndex].count) + Number(newData.count)
        ).toString();
      } else {

      }
      let nietos = [];
      nietos = addSamplingList.concat(newData);
      if (dataIndex === -1) {
        await setAddSamplingList(nietos)
      }
      setAddError(false)
      setAddCount("")
      setCountError(false)
    } else {
      setCountError(true)
    }
  }
  const removeList = (e) => {
    let myArray;
    myArray = addSamplingList.filter(function (obj) {
      return obj.option !== e.currentTarget.id;
    });
    setAddSamplingList(myArray)
  }
  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const loadSpeciesList = async () => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": 10,
      "name": "",
      "status": "",
      "toBeFiltered": false
    }
    setLoading(true);
    const plantationResponse = await ds.speciesList({ inputs });
    setLoading(false);
    setSpeciesList(plantationResponse.data.species);
  };


  const fileLoad = async (e) => {
    await gelocationAdd()
    setLoading(true)
    const file = e.target.files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        const filesData = [
          ...filesList,
          { result },
        ]
        setFilesList(filesData)
        setFileError(false)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err);
      });
  }
  const onSubmit = async (data, event) => {
    event.preventDefault();
    if (plantValue === "Multiple" && addSamplingList.length === 0) {
      setAddError(true);
    }
    if (filesList.length === 0) {
      setFileError(true);
    }
    let values;
    if (addError === false && fileError === false) {
      if (plantValue === "Multiple" && addError === false && addSamplingList.length !== 0) {
        values = [...addSamplingList]
      }
      if (plantValue === "Single") {
        values = {
          "speciesCount": data.count,
          "speciesId": speiceOption,
        }
      }
      let formData = {
        files: filesList,
        saplingsData: values
      }
      let storeValues = [{
        ...listShow,
        ...formData
      }]

      let addSpecie = {
        "plantationId": formRegisterData.id,
        "plantType": 1,
        "latitude": latitude,
        "longitude": longitude,
        "speciesDetails": values,
        "pictureDetails": [
          { "pictureUrl": " https://rameshpandi.s3.ap-south-1.amazonaws.com/fbh2images/test1.jpg" },
          { "pictureUrl": " https://rameshpandi.s3.ap-south-1.amazonaws.com/fbh2images/test2.jpg" },
          { "pictureUrl": " https://rameshpandi.s3.ap-south-1.amazonaws.com/fbh2images/test3.jpg" },
          { "pictureUrl": " https://rameshpandi.s3.ap-south-1.amazonaws.com/fbh2images/test4.jpg" }
        ]
      }

      setListShow(storeValues)
      if (storeValues.length !== 0 && addError === false && fileError === false) {
        const plantationResponse = await ds.loadAddSpecies(addSpecie);
        if (plantationResponse !== undefined) {
          if (plantationResponse.status === 200) {
            let registerData = plantationResponse
            navigate("/plantation-grid", { state: { registerData } })
            toast.success("Form submitted successfully");
            setLoading(false);
          } else {
            toast.error("somethig went wrong");
            setLoading(false);

          }
        } else {
          setLoading(false);
        }
      }

      // axios.post("url", formData).then((response) => {
      //   if (response.status === 200) {

      //   } else {

      //   }
      // });
    }
    // setNextpage(true);


  }
  const gelocationAdd = async () => {
    await setAllowFiles(true)
    await navigator.geolocation.watchPosition(function (position) {
      setPositionValue(position.coords.latitude + " : " + position.coords.longitude)
      setLatitude(position.coords.latitude)
      setLongitude(position.coords.longitude)
    });
  }
  useEffect(() => {
    //  let data = { "id": "23", "registrationId": "88acbcae-d34b-4836-9a52-c2f7fdeb35ba", "planterName": "Ramesh", "project": { "id": "1", "name": "Heartfulness Institute", "description": "Heartfulness Institute", "projectImageUrls": ["https://rameshpandi.s3.ap-south-1.amazonaws.com/forestimages/add-image.png", "\nhttps://rameshpandi.s3.ap-south-1.amazonaws.com/forestimages/little-boy-sapling.png", "\nhttps://rameshpandi.s3.ap-south-1.amazonaws.com/forestimages/malabar-neelam.png"], "projectStartDate": "2022-01-21T10:27:48Z" }, "planterFirebaseId": "ezeAFIPTdsOT7Hm1Cu91eFV3qxv2", "attributeMap": { "country": "India", "pincode": "500085", "state": "Telangana", "city": "Hyderabad" }, "plantationDate": "2022-02-17T18:30:00Z", "reviewStatus": "PENDING" }
    loadSpeciesList()
    setFormRegisterData(location.state.registerData);
    //  setFormRegisterData(data);
  }, []);
  return (
    <>
      <SEO title='Forests By Heartfulness | Add Saplings' />
      <Layout>
        <div>
          <Plantationbreadcumb backTo="/registeryour-plantation" name="Register Your Plantation" />
        </div>
        <div className='plantation-breadsection'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8'>
                <h3 className='register-text'> Register Your Plantation </h3>
                <h3 className='title-item'>{formRegisterData && formRegisterData.project !== "" ? <> {formRegisterData.project.name}</> : ""} </h3>
                <h3 className='bred-regid'> Reg Id #{formRegisterData && formRegisterData !== "" ? <> {formRegisterData.registrationId}</> : ""} </h3>
                <h5 className='bred-datetime'>{formRegisterData && formRegisterData !== "" ? <>  {moment(new Date((formRegisterData.plantationDate))).format(("ddd, MMM D YYYY"))}</> : ""}</h5>
              </div>
              <div className='col-md-4 text-right not-activate'>
                <button className='btn btn-approve'> Send for Admin Approval </button>
                <button className='btn btn-add'> Add Saplings </button>
              </div>

              {listShow.length === "00000" ? <div className='row mg-top20'>
                {listShow.map((item) => {
                  return (
                    <div className='col-md-5'>
                      <div className='iniative-card' key={item.id}>
                        <div className='wid-55'>
                          <Viewthubnailslider files={item.files} />
                        </div>

                        <div className='iniativecard-body wid-45'>
                          <p className='text-city'>
                            {truncate(item.trackid, {
                              length: 125,
                              omission: '...',
                            })}
                            <span className='text-right icon-rightposi'>
                              <img src={penciledit} className='img-fluid' alt='Edit Icon' />
                              <img src={binicon} className='img-fluid' alt='Delete Icon' />
                            </span>
                          </p>

                          <p className='date-text'>
                            {truncate(item.tracknumber, {
                              length: 125,
                              omission: '...',
                            })}
                          </p>

                          <p className='green-clr sapling-text'>
                            {truncate(item.desc, {
                              length: 125,
                              omission: '...',
                            })}
                          </p>
                          <p className='text-city'>
                            {truncate(item.planttype, {
                              length: 100,
                              omission: '...',
                            })}
                          </p>

                          <p className='date-text'>
                            {truncate(item.type, {
                              length: 125,
                              omission: '...',
                            })}
                          </p>
                          <p className='text-city'>
                            {truncate(item.species, {
                              length: 100,
                              omission: '...',
                            })}
                          </p>
                          <p className='date-text'>
                            {truncate(item.producttype, {
                              length: 125,
                              omission: '...',
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
                : null}

              <div className='row'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='planttype' className='form-label'>
                          Plant type
                        </label>
                        <select name='planttype' id='planttype' className='form-control' onChange={plantTypeChange}>
                          <option value='Multiple'>Multiple</option>
                          <option value='Single'> Single </option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='fc-wrapper buttonIn'>
                        <label htmlFor='Geotag' className='form-label'>
                          Geotag
                        </label>
                        <input type='text' name='Geotag'
                          value={positionValue !== "" ? `${positionValue}` : ""}
                          className='form-control ' id='Geotag' placeholder='' />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <h3 className='iniativecard-title'> ADD SPECIES DETAILS </h3>
                      <br />
                      {addError === true ?
                        <span className="form-error">
                          Add at least one Sapling
                        </span>
                        : null}

                      {addSamplingList && addSamplingList.map(data => {
                        return (
                          <div className='dis-flex cent-item-mob mg-top20'>
                            <div className='green-selectbox'>
                              <span>
                                {' '}
                                <img src={speciestreeicon} className='img-fluid' alt='SPECIES DETAILS' />{' '}
                              </span>

                              <h5> {data.count} &nbsp; {data.option} </h5>
                              <span className='small-close' >
                                {' '}
                                <p id={data.option} onClick={removeList}> <img src={closesmall} className='img-fluid' alt='Close small' />{' '}</p>
                              </span>
                            </div>&nbsp;&nbsp;
                          </div>
                        )
                      })}
                    </div>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='species' className='form-label'>
                          Species
                        </label>
                        <select name='species' id='species' className='form-control' onChange={speciesChange}>
                          {speciesList.map(data => {
                            return <option value={data.id}>{data.speciesName}</option>
                          })}
                          {/* <option value='Mango'>Mango</option>
                        <option value='Neem'> Neem </option> */}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='Count' className='form-label'>
                          Count
                        </label>
                        {plantValue === "Multiple" ? <input type='text' name='count'
                          className='form-control ' id='count' placeholder='' value={addCount} onChange={countChange} />
                          : null}
                        {plantValue === "Single" ? <input type='text' name='count'
                          {...register("count", { required: "Count filed is required" })}
                          className='form-control ' id='count' placeholder='' /> : null}
                        {errors.count && (
                          <span className="form-error">
                            {errors.count.message}
                          </span>
                        )}
                        {countError === true ?
                          <span className="form-error">
                            Please add the count
                          </span>
                          : null}
                      </div>
                    </div>
                    {plantValue === "Multiple" ? <div className='col-md-4'>
                      <button className='btn btn-add' onClick={addSaplings}> Add </button>
                    </div> : null}
                    <div className='col-md-8'></div>

                    <div className='col-md-12 mg-top30'>
                      <h3 className='iniativecard-title'> ADD IMAGES </h3>

                      <div className='dis-flex cent-item-mob'>
                        {filesList && filesList.map(data => {
                          return (<img src={data.result} className='img-fluid' alt='Add image List' width="150px" height="150px" />)

                        })}

                        <div className='border-items'>
                          <span className='plus-icon-green btn-file'>
                            <i className='icon icon-plus-lg plus-symbol-custom-icon'></i>
                            <input type="file" onChange={fileLoad} accept="image/png, image/gif, image/jpeg" />Add more
                          </span>
                          {/* <p><input type="file" onChange={fileLoad} /> Add more</p> */}
                        </div>
                      </div>
                      <div> <br />
                        {fileError === true ? <span className="form-error">Add at least one Image</span> : null}
                      </div>

                      <div className='text-right'>
                        <button className='btn btn-approve save-width'> Save </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop={false}
            hideProgressBar
            closeOnClick
            rtl={false}
          />
          <Modal show={loading} centered className="modal-con">
            <div className="modal-con">
              <Spinner style={{ textAlign: "center" }} animation="border" />
            </div>
          </Modal>
        </div>
      </Layout>
    </>
  );
}

export default Plantationregister;